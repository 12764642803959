<template>
  <div class="documents crm-container">
    <div class="crm-container crm-container-title">
      <span class="crm-title">
        Документы
      </span>
    </div>
    <div class="types">
      <router-link
        class="type-item"
        to="/legal-entities"
      >
        <div class="type-item__title">
          Юридические лица клиник
        </div>
        <clinic-icon class="type-item__icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import ClinicIcon from 'assets/images/clinic-icon.svg';

export default {
  name: 'Documents',
  page: {
    title: 'CRM Doctis - Документы',
  },
  components: {
    ClinicIcon,
  },
  mixins: [mixinRoles],
  methods: {},
};
</script>

<style lang="scss" scoped>
.documents {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.crm-container-title {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
}
.types {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.type-item {
  background-color: #DDE3FC;
  width: 47%;
  height: 292px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: darken(#DDE3FC, 5);
  }
  &__title {
    color: $blue-dark;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    position: absolute;
    top: 50px;
    left: 10%;
  }
  &__icon {
    position: absolute;
    right: 15%;
    bottom: 50px;
  }
}
</style>
